<template>
    <div>
        <v-row class="table-actions-row">
            <v-col sm="4" cols="12" class="pl-0">
                <breadcrumbs :items="crumbs"> </breadcrumbs>
            </v-col>
            <v-col sm="8"> </v-col>
        </v-row>
        <localization :language="'ru-RU'">
            <intl :locale="'ru'">
                <Grid
                    ref="grid"
                    class="built-in-params"
                    :filterable="true"
                    :filter="filter"
                    @datastatechange="dataStateChange"
                    :pageable="gridPageable"
                    :take="take"
                    :skip="skip"
                    :page-size="pageSize"
                    :total="total"
                    :data-items="dataResult"
                    :columns="columns"
                    :expand-field="'expanded'"
                    @expandchange="expandChange"
                >
                    <template v-slot:actions="{ props: { dataItem } }">
                        <td>
                            <div class="actionsColumn">
                                <CButton
                                    @click="
                                        setCurrentItem(dataItem)
                                        showEntityModal = true
                                    "
                                    class="actions-btn btn-square btn-info"
                                >
                                </CButton>
                            </div>
                        </td>
                    </template>
                    <template v-slot:type="{ props: { dataItem } }">
                        <td>
                            {{ paramLabels[dataItem.type] }}
                        </td>
                    </template>
                    <template v-slot:value="{ props: { dataItem } }">
                        <td>
                            {{
                            selectable(dataItem.type)
                                    ? getValueFromListSelect(dataItem.value)
                                    : dataItem.value
                            }}
                        </td>
                    </template>
                </Grid>
            </intl>
        </localization>
        <parameters-page-modal
            v-if="showEntityModal"
            :current-item="currentItem"
            :show-dialog="showEntityModal"
            :select-list="dealersShowBalanceList"
            :selectable="selectable(currentItem.type)"
            @click:outside="showEntityModal = false"
            @stored="entityStored($event)"
            @close="showEntityModal = false"
        ></parameters-page-modal>
    </div>
</template>

<script>
    import TableMixin from '@/views/grid_elements/mixins/TableMixin'
    import ResizableTableMixin from '@/views/grid_elements/mixins/ResizableTableMixin'
    import { Grid } from '@progress/kendo-vue-grid'
    import {
        IntlProvider,
        LocalizationProvider,
    } from '@progress/kendo-vue-intl'
    import { mapGetters } from 'vuex'
    import Breadcrumbs from '@/views/components/breadcrumbs'
    import BuiltInParametersUpdateModal from './modals/BuiltInParametersUpdateModal'
    import '@/views/grid_elements/translations'
    import api from '@/api'
    import { toDataSourceRequestString } from '@progress/kendo-data-query'

    export default {
        name: 'built-in-params',
        components: {
            Grid: Grid,
            breadcrumbs: Breadcrumbs,
            'parameters-page-modal': BuiltInParametersUpdateModal,
            localization: LocalizationProvider,
            intl: IntlProvider,
        },
        mixins: [TableMixin, ResizableTableMixin],
        async mounted() {
            await this.getData()
        },
        data() {
            return {
                skip: 0,
                take: 10,
                pageSize: 10,
                gridPageable: { pageSizes: true },
                total: 0,
                columns: [
                    {
                        field: 'id',
                        title: 'Действия',
                        cell: 'actions',
                        minWidth: 100,
                        width: '100px',
                        filterable: false,
                    },
                    {
                        field: 'type',
                        title: 'Тип параметра',
                        cell: 'type',
                        filterable: false,
                    },
                    {
                        field: 'value',
                        title: 'Значение',
                        cell: 'value',
                        filterable: false,
                    },
                ],
                dealersShowBalanceList: [
                    {
                        label: 'Да',
                        value: 1,
                    },
                    {
                        label: 'Нет',
                        value: 0,
                    },
                ],
                paramLabels: {
                  1: 'E-mail контролёра',
                  2: 'E-mail склада (отдел ЛЛК)',
                  3: 'Закрыть возможность оформить заказ для дилеров до',
                  5: 'Отображать баланс по товарам дилеров',
                  6: 'Включить логирование',
                  7: 'Отображать баланс по товарам с ограниченным количеством',
                },
                selectableTypes: [5, 6, 7],
            }
        },
        computed: {
            ...mapGetters('catalog', ['productCategoriesShort', 'sectionList']),
        },
        methods: {
            selectable(paramType) {
                return this.selectableTypes.includes(paramType);
            },
            getValueFromListSelect(status) {
                return this.dealersShowBalanceList
                    .find((showBalanceItem) => +showBalanceItem.value === +status)?.label || '';
            },
            prepareFetchParams() {
                return [
                    this.page,
                    this.take,
                    toDataSourceRequestString({
                        filter: this.filter,
                        sort: this.sort,
                    }),
                ]
            },
            fetchData: api.system_params.systemParams,
        },
    }
</script>

<style scoped></style>
