var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-row',{staticClass:"table-actions-row"},[_c('v-col',{staticClass:"pl-0",attrs:{"sm":"4","cols":"12"}},[_c('breadcrumbs',{attrs:{"items":_vm.crumbs}})],1),_c('v-col',{attrs:{"sm":"8"}})],1),_c('localization',{attrs:{"language":'ru-RU'}},[_c('intl',{attrs:{"locale":'ru'}},[_c('Grid',{ref:"grid",staticClass:"built-in-params",attrs:{"filterable":true,"filter":_vm.filter,"pageable":_vm.gridPageable,"take":_vm.take,"skip":_vm.skip,"page-size":_vm.pageSize,"total":_vm.total,"data-items":_vm.dataResult,"columns":_vm.columns,"expand-field":'expanded'},on:{"datastatechange":_vm.dataStateChange,"expandchange":_vm.expandChange},scopedSlots:_vm._u([{key:"actions",fn:function(ref){
var dataItem = ref.props.dataItem;
return [_c('td',[_c('div',{staticClass:"actionsColumn"},[_c('CButton',{staticClass:"actions-btn btn-square btn-info",on:{"click":function($event){_vm.setCurrentItem(dataItem)
                                    _vm.showEntityModal = true}}})],1)])]}},{key:"type",fn:function(ref){
                                    var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.paramLabels[dataItem.type])+" ")])]}},{key:"value",fn:function(ref){
                                    var dataItem = ref.props.dataItem;
return [_c('td',[_vm._v(" "+_vm._s(_vm.selectable(dataItem.type) ? _vm.getValueFromListSelect(dataItem.value) : dataItem.value)+" ")])]}}])})],1)],1),(_vm.showEntityModal)?_c('parameters-page-modal',{attrs:{"current-item":_vm.currentItem,"show-dialog":_vm.showEntityModal,"select-list":_vm.dealersShowBalanceList,"selectable":_vm.selectable(_vm.currentItem.type)},on:{"click:outside":function($event){_vm.showEntityModal = false},"stored":function($event){return _vm.entityStored($event)},"close":function($event){_vm.showEntityModal = false}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }