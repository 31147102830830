var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{attrs:{"slim":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var untouched = ref.untouched;
var handleSubmit = ref.handleSubmit;
return [_c('v-dialog',{attrs:{"value":_vm.showDialog,"persistent":false,"width":"600"},on:{"input":_vm.closeDialog}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v("Редактирование встроенного параметра")]),_c('v-card-text',[_c('form',[_c('v-layout',{attrs:{"column":""}},[_c('ValidationProvider',{attrs:{"name":"value","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
var validated = ref.validated;
return [(_vm.selectable)?_c('v-select',{attrs:{"hide-details":"auto","label":"Значение параметра *","error":invalid && validated,"item-text":"label","item-value":"value","items":_vm.selectList},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}}):_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.dateVisible !== 'C8760B91-A0A8-159B-3552-103545121215' ? '' : '##-##-####'),expression:"dateVisible !== 'C8760B91-A0A8-159B-3552-103545121215' ? '' : '##-##-####'"}],attrs:{"error":invalid && validated,"label":"Значение параметра *","hide-details":"auto"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}})]}}],null,true)}),(_vm.dateVisible === 'C8760B91-A0A8-159B-3552-103545121215')?_c('div',{staticClass:"date-alert"},[_vm._v("*формат даты дд-мм-гггг")]):_vm._e()],1)],1)]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('CButton',{staticClass:"mr-3",attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v("Отмена")]),_c('CButton',{attrs:{"disabled":invalid,"color":"success"},on:{"click":function($event){return handleSubmit(_vm.submit)}}},[_c('i',{staticClass:"fa fa-save mr-2"}),_c('span',[_vm._v("Сохранить")])])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }