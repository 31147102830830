<template>
  <ValidationObserver slim v-slot="{ invalid, untouched, handleSubmit }">
    <v-dialog
        :value="showDialog"
        :persistent="false"
        width="600"
        @input="closeDialog"
    >
      <v-card>
        <v-card-title class="headline">Редактирование встроенного параметра</v-card-title>
        <v-card-text>
          <form>
            <v-layout column>
              <ValidationProvider name="value" rules="required" v-slot="{invalid, validated}">
                <v-select
                    v-if="selectable"
                    v-model="value"
                    hide-details="auto"
                    label="Значение параметра *"
                    :error="invalid && validated"
                    item-text="label"
                    item-value="value"
                    :items="selectList"
                />
                <v-text-field
                    v-else
                    :error="invalid && validated"
                    label="Значение параметра *"
                    hide-details="auto"
                    v-model="value"
                    v-mask="dateVisible !== 'C8760B91-A0A8-159B-3552-103545121215' ? '' : '##-##-####'"
                ></v-text-field>
              </ValidationProvider>
              <div v-if="dateVisible === 'C8760B91-A0A8-159B-3552-103545121215'" class="date-alert">*формат даты дд-мм-гггг</div>
            </v-layout>
          </form>

        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <CButton class="mr-3" color="secondary" @click="$emit('close')">Отмена</CButton>
          <CButton :disabled="invalid" @click="handleSubmit(submit)" color="success">
            <i class="fa fa-save mr-2"></i>
            <span>Сохранить</span>
          </CButton>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </ValidationObserver>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import EntityModalMixin from '@/views/grid_elements/mixins/EntityModalMixin';
import api from '@/api';

export default {
  name: "BuiltInParametersUpdateModal",
  components: {
    ValidationProvider,
    ValidationObserver
  },
  mixins: [EntityModalMixin],
  props: {
    selectable: {
      type: Boolean,
      default: false,
    },
    selectList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    let state = {
      id: '',
      value: ''
    };

    if (this.currentItem) {
      state = {
        ...state,
        ...this.currentItem
      }
    }
    let text = '';
    const dateVisible = state.id
    return {
        state,
        dateVisible,
        text,
        value: null,
    };
  },
  methods: {
    updateEntity: api.system_params.updateSystemParam,
  },
  computed: {
    data() {
      return {
        value: this.value ?? this.text
      }
    }
  },
}
</script>

<style scoped>
.date-alert {
  color: red;
  margin: 5px 0;
}
</style>
